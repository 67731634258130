import React from "react";
import { ICarColor } from "../../../interfaces";

interface ICarColorSelect {
  carColors: ICarColor[];
  selectedCarColor: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const CarColorSelect: React.FC<ICarColorSelect> = ({
  carColors,
  selectedCarColor,
  onChange,
}) => (
  <div>
    <p>Цвет</p>
    <select value={selectedCarColor} onChange={onChange}>
      <option value="">Выберите цвет авто</option>
      {carColors.map((color) => (
        <option key={color.id} value={color.id}>
          {color.name}
        </option>
      ))}
    </select>
  </div>
);

export default CarColorSelect;
