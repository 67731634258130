import { useEffect, useState } from "react";
import { ICarBrand } from "../interfaces";

const useFetchCarBrands = (currentTime: number) => {
  const [carBrandList, setCarBrandList] = useState<ICarBrand[]>([]);

  useEffect(() => {
    const fetchCarBrands = async () => {
      try {
        const response = await fetch(
          `/api/get_car_brand?current_time=${currentTime}`
        );
        const data = await response.json();

        const brandEntries = Object.entries(data.result.brand);
        const brands: ICarBrand[] = brandEntries.map(([id, name]) => ({
          id,
          name: name as string,
        }));

        setCarBrandList(brands);
      } catch (error) {
        console.error("Error fetching car brands:", error);
      }
    };

    fetchCarBrands();
  }, [currentTime]);

  return carBrandList;
};

export default useFetchCarBrands;
