import React from "react";
import styles from "./SuccessModal.module.scss";
import { useNavigate } from "react-router-dom";
import googlePlayIcon from "../../assets/GOOGLE.png";
import appStoreButton from "../../assets/APPLE.png";


const SuccessModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <h1>Регистрация прошла успешно!</h1>
        <div className={styles.info}>
          Добро пожаловать в семью SMART Taxi!. <br />
          Вы сможете авторизоваться в
          приложении для водителей SMART Taxi Водитель, которое можно скачать по
          ссылке ниже.
        </div>
        <div className={styles.storeBtns}>
          <a
            href="https://play.google.com/store/apps/details?id=com.smarttrans.driver&hl=gsw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={googlePlayIcon}
              alt="Google Play"
              className={styles.appButton}
            />
          </a>
          <a
            href="https://apps.apple.com/ua/app/smart-taxi-%D0%B2%D0%BE%D0%B4%D0%B8%D1%82%D0%B5%D0%BB%D1%8C/id6502894777?l=ru"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={appStoreButton}
              alt="AppStore"
              className={styles.appButton}
            />
          </a>
        </div>
        <button
          className={styles.closeBtn}
          onClick={() => {
            onClose();
            navigate("/");
          }}
        >
          Закрыть
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
