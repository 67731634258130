import React from "react";
import { ICarBrand } from "../../../interfaces";

interface CarBrandSelectProps {
  carBrands: ICarBrand[];
  selectedCarBrand: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const CarBrandSelect: React.FC<CarBrandSelectProps> = ({
  carBrands,
  selectedCarBrand,
  onChange,
}) => (
  <div>
    <p>Марка</p>
    <select value={selectedCarBrand} onChange={onChange} required>
      <option value="">Выберите марку авто</option>
      {carBrands.map((brand) => (
        <option key={brand.id} value={brand.id}>
          {brand.name}
        </option>
      ))}
    </select>
  </div>
);

export default CarBrandSelect;
