import React, { useEffect, useState } from "react";
import logo from "../../../assets/logo.png";
import styles from "./RegisterPage.module.scss";
import useFetchBranches from "../../../hooks/useFetchBranches";
import useFetchCarBrands from "../../../hooks/useFetchCarBrands";
import useFetchCarColors from "../../../hooks/useFetchCarColors";
import { ICarModel } from "../../../interfaces";
import BranchSelect from "../../forms/BranchSelect";
import CarBrandSelect from "../../forms/carForms/CarBrandSelect";
import CarColorSelect from "../../forms/carForms/CarColorSelect";
import CarModelSelect from "../../forms/carForms/CarModelSelect";
import SuccessModal from "../../modal/SuccessModal";
import PrivacyPolicy from "../Privacy/PrivacyPolicy";

const RegisterDriverPage: React.FC = () => {
  const currentTime = Math.floor(Date.now() / 1000) + 3 * 3600;
  const [showModal, setShowModal] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [showAgreement, setShowAgreement] = useState(false);

  const branchList = useFetchBranches(currentTime);
  const carBrandList = useFetchCarBrands(currentTime);
  const carColorList = useFetchCarColors(currentTime);

  const [carModelList, setCarModelList] = useState<ICarModel[]>([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedCarBrand, setSelectedCarBrand] = useState("");
  const [formData, setFormData] = useState({
    activate: "1",
    birthday: "",
    car_color: "",
    car_brand: "",
    car_model: "",
    car_year: "",
    gos_number: "",
    card_number: "",
    driver_card_name: "",
    driver_card_surname: "",
    car_class: "",
    car_owner: "WORKER",
    city_id: "",
    last_name: "",
    name: "",
    second_name: "",
    phone: "",
    position_id: "1",
    partnership: "STAFF",
    current_time: currentTime,
  });

  useEffect(() => {
    const fetchCarModels = async () => {
      if (selectedCarBrand) {
        try {
          const response = await fetch(
            `/api/get_car_model_list?brand_id=${selectedCarBrand}&current_time=${currentTime}`
          );
          const data = await response.json();

          const modelEntries = Object.entries(data);
          const models: ICarModel[] = modelEntries.map(([id, name]) => ({
            id,
            name: name as string,
          }));

          setCarModelList(models);
        } catch (error) {
          console.error("Error fetching car models:", error);
        }
      }
    };

    fetchCarModels();
  }, [selectedCarBrand, currentTime]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const noSpacesValue = value.replace(/\s/g, "");

    let formattedValue = noSpacesValue;
    if (name === "last_name" || name === "name" || name === "second_name") {
      formattedValue =
        noSpacesValue.charAt(0).toUpperCase() + noSpacesValue.slice(1);
    } else if (name === "driver_card_name" || name === "driver_card_surname") {
      formattedValue = noSpacesValue.toUpperCase();
      // Проверяем, что введенные символы являются латинскими буквами
      if (/^[a-zA-Z]+$/.test(noSpacesValue)) {
        formattedValue = noSpacesValue.toUpperCase();
      } else {
        // Если введены символы, не являющиеся латинскими буквами, очищаем значение
        formattedValue = "";
      }
    } else if (name === "gos_number") {
      formattedValue = noSpacesValue.toLowerCase();
      // Проверяем, что введенные символы являются латинскими буквами
    }

    setFormData({ ...formData, [name]: formattedValue });
  };

  const handlePhoneChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const value = e.target.value;
    const cleanedValue = value.replace(/\D/g, "");
    if (cleanedValue.startsWith("7")) {
      const formattedValue = "+7" + cleanedValue.slice(1);
      if (formattedValue.length <= 12) {
        setFormData({ ...formData, phone: formattedValue });
      }
    } else if (cleanedValue.startsWith("8")) {
      const formattedValue = "+7" + cleanedValue.slice(1);
      if (formattedValue.length <= 12) {
        setFormData({ ...formData, phone: formattedValue });
      }
    } else if (cleanedValue === "") {
      setFormData({ ...formData, phone: "" });
    } else {
      const formattedValue = "+7" + cleanedValue;
      if (formattedValue.length <= 12) {
        setFormData({ ...formData, phone: formattedValue });
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isAgreed) {
      alert("Please agree to the processing of personal data.");
      return;
    }

    try {
      const response = await fetch(`/api/create_worker_profile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to create driver profile");
      }

      setShowModal(true);
    } catch (error) {
      console.error("Error creating driver profile:", error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.logoCont}>
          <img src={logo} alt="" />
          <div className={styles.title}>SMART Taxi</div>
        </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          {/* <div className={styles.position}>
            <p className={styles.positionTitle}>Деятельность</p>
            <div className={styles.positionInfo}>
              <label>
                <input
                  type="radio"
                  name="position_id"
                  value="1"
                  checked={formData.position_id === "1"}
                  onChange={handleInputChange}
                />
                Водитель такси
              </label>
              <label>
                <input
                  type="radio"
                  name="position_id"
                  disabled={true}
                  value="2"
                  checked={formData.position_id === "2"}
                  onChange={handleInputChange}
                />
                Автокурьер
              </label>
              <label>
                <input
                  type="radio"
                  name="position_id"
                  value="3"
                  disabled={true}
                  checked={formData.position_id === "3"}
                  onChange={handleInputChange}
                />
                Водитель грузового такси
              </label>
            </div>
          </div> */}
          <div className={styles.driver}>
            <p className={styles.driverTitle}>Личная информация</p>
            <div className={styles.driverInfo}>
              <div className={styles.lastName}>
                <p className={styles.lastNameTitle}>Фамилия</p>
                <input
                  type="text"
                  name="last_name"
                  placeholder="Иванов"
                  required
                  minLength={2}
                  value={formData.last_name}
                  onChange={handleInputChange}
                />
              </div>
              <div className={styles.name}>
                <p className={styles.nameTitle}>Имя</p>
                <input
                  type="text"
                  name="name"
                  placeholder="Иван"
                  required
                  minLength={2}
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className={styles.secondName}>
                <p className={styles.secondNameTitle}>Отчество</p>
                <input
                  type="text"
                  name="second_name"
                  required
                  minLength={2}
                  placeholder="Иванович"
                  value={formData.second_name}
                  onChange={handleInputChange}
                />
              </div>
              <div className={styles.phone}>
                <p className={styles.phoneTitle}>Телефон</p>
                <input
                  type="tel"
                  name="phone"
                  placeholder="+79281234567"
                  required
                  value={formData.phone}
                  minLength={12}
                  onChange={handlePhoneChange}
                />
              </div>
              <div className={styles.birthday}>
                <p className={styles.birthdayTitle}>Дата рождения</p>
                <input
                  type="date"
                  name="birthday"
                  placeholder="дд.мм.гггг"
                  required
                  value={formData.birthday}
                  onChange={handleInputChange}
                />
              </div>
              <BranchSelect
                branches={branchList}
                selectedBranch={selectedBranch}
                onChange={(e) => {
                  setSelectedBranch(e.target.value);
                  setFormData({ ...formData, city_id: e.target.value });
                }}
              />
              <div className={styles.cardNumber}>
                <p className={styles.cardNumberTitle}>Номер банковской карты</p>
                <input
                  type="text"
                  name="card_number"
                  minLength={16}
                  maxLength={16}
                  placeholder="XXXX XXXX XXXX XXXX"
                  required
                  value={formData.card_number}
                  onChange={handleInputChange}
                />
              </div>
              <div className={styles.cardName}>
                <p className={styles.cardNameTitle}>Имя латиницей</p>
                <input
                  type="text"
                  minLength={1}
                  required
                  placeholder="IVAN"
                  name="driver_card_name"
                  value={formData.driver_card_name}
                  onChange={handleInputChange}
                />
              </div>
              <div className={styles.cardLastname}>
                <p className={styles.cardLastnameTitle}>Фамилия латиницей</p>
                <input
                  type="text"
                  minLength={1}
                  required
                  placeholder="IVANOV"
                  name="driver_card_surname"
                  value={formData.driver_card_surname}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className={styles.car}>
              <p className={styles.carInfoTitle}>Автомобиль</p>
              <div className={styles.carInfo}>
                <CarBrandSelect
                  carBrands={carBrandList}
                  selectedCarBrand={selectedCarBrand}
                  onChange={(e) => {
                    const selectedBrand = e.target.value;
                    setSelectedCarBrand(selectedBrand);
                    setFormData({ ...formData, car_brand: selectedBrand });
                  }}
                />
                <CarModelSelect
                  carModels={carModelList}
                  selectedCarModel={formData.car_model}
                  onChange={(e) => {
                    setFormData({ ...formData, car_model: e.target.value });
                  }}
                />
                <CarColorSelect
                  carColors={carColorList}
                  selectedCarColor={formData.car_color}
                  onChange={(e) => {
                    setFormData({ ...formData, car_color: e.target.value });
                  }}
                />
                <div>
                  <p className={styles.carYear}>Год выпуска авто</p>
                  <select
                    name="car_year"
                    value={formData.car_year}
                    required
                    onChange={handleInputChange}
                  >
                    <option value="">Выберите год выпуска авто</option>
                    {Array.from({ length: 36 }, (_, index) => {
                      const year = 1995 + index;
                      return (
                        <option key={year} value={year.toString()}>
                          {year}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <p className={styles.carClassTitle}>Класс авто</p>
                  <select
                    name="car_class"
                    value={formData.car_class}
                    required
                    onChange={handleInputChange}
                  >
                    <option value="">Выберите класс авто</option>
                    <option value="1">Эконом</option>
                    <option value="2">Комфорт</option>
                    <option value="3">Бизнес</option>
                    <option value="4">VIP</option>
                  </select>
                </div>
                <div>
                  <p className={styles.gosNomerTitle}>Гос номер</p>
                  <input
                    type="text"
                    name="gos_number"
                    placeholder="а001аа95"
                    minLength={3}
                    maxLength={9}
                    required
                    value={formData.gos_number}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.agreement}>
            <input
              type="checkbox"
              checked={isAgreed}
              onChange={(e) => setIsAgreed(e.target.checked)}
            />
            Я согласен на обработку персональных данных
            <p className={styles.policy} onClick={() => setShowAgreement(true)}>
              Политика конфиденциальности
            </p>
          </div>
          <button type="submit" disabled={!isAgreed}>
          Создать аккаунт
          </button>
        </form>
        {showAgreement && (
          <PrivacyPolicy onClose={() => setShowAgreement(false)} />
        )}
        {showModal && <SuccessModal onClose={() => setShowModal(false)} />}
      </div>
    </div>
  );
};

export default RegisterDriverPage;
