import React from "react";
import logo from "../../../assets/logo.png";
import styles from "./PrivacyPolicy.module.scss";

const PrivacyPolicy: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img src={logo} alt="SMART Taxi" className={styles.logo} />

        <div className={styles.title}>Политика конфиденциальности</div>

        <p className={styles.text}>
          Дата последнего обновления: 07.01.2024
          <br />
          Добро пожаловать в SMART Taxi (далее – Сервис, мы)! Обеспечение
          конфиденциальности ваших персональных данных - важный принцип нашей
          работы. В этой политике описано, как мы обрабатываем ваши персональные
          данные, какие данные мы собираем, с какими целями их используем и
          передаем, а также указаны ваши права и ваш выбор в отношении ваших
          данных.
          <br />
          Настоящая Политика конфиденциальности распространяется на наши услуги
          при использовании Приложения независимо от места их предоставления. Мы
          обязуемся соблюдать местное законодательство в отношении всех
          процессов, описанных в политике. В случае несоответствия между
          настоящей политикой и местным законодательством мы обязуемся соблюдать
          местное законодательство в части такого несоответствия.
          <br />
          Настоящая политика распространяется на всех пользователей Сервиса.
          любого мобильного приложения Сервиса («Приложение») и любых услуг,
          которые мы предоставляем через Сайт или Приложения (совместно
          именуемых «Услуги»), а также при обращении к нам по телефону,
          электронной почте, путем переписки, в социальных сетях, лично или при
          помощи других способов связи. Настоящая политика не распространяется
          на обработку данных наших работников и сотрудников, корпоративных
          партнеров, подрядчиков и субподрядчиков.
          <br />
          Скачивая и устанавливая Приложение, или используя сервисы и услуги
          Сайта, вы безоговорочно соглашаетесь на обработку ваших данных в
          порядке, описанном в настоящей политике. При несогласии с настоящей
          Политикой, вы должны немедленно прекратить использование в любой форме
          Приложения и Сайта.
          <br />
          Персональные данные, собираемые и обрабатываемые нами используются
          исключительно в целях, на которые Пользователями предоставлено
          согласие.
          <br />
          Основные термины и определения
          <br />В настоящей Политике конфиденциальности, если из текста не
          вытекает иное, используются следующие термины: <br />
          1. «Компания, Мы» - ООО "Смарт транс такси" <br />
          2. «Приложение» - программный комплекс для автоматизации службы такси
          "SMART Taxi", а также весь набор связанных продуктов, включая
          приложение для водителей, пассажиров, набор вспомогательных сервисов.
          Приложение размещено в Google Play на основании лицензионного
          соглашения. <br />
          3. «Персональные данные» - любая информация, относящаяся к прямо или
          косвенно к Пользователю и/или третьим лицам, информация о которых
          предоставляется Пользователем с использованием Приложения.
          <br />
          4. «Пользователь Приложения / Пользователь» – любое физическое или
          юридическое лицо, или индивидуальный предприниматель, использующие
          Приложение в любом объеме и с которым у Компании заключен Договор.
          Лица, осуществляющие перевозку Пользователей, именуются
          «Исполнителями». Лица, осуществляющие заказ поездок, именуются
          «Клиентами». <br />
          5. «Договор» - любой договор и/или соглашение, включая лицензионное
          соглашение, заключенный с Пользователем, информация о котором доступна
          в Приложении. <br />
          6. Все остальные термины и определения, встречающиеся в тексте
          Политики конфиденциальности, толкуются в соответствии с текстом
          Политики, Договором и законодательством Российской Федерации. <br />
          7. Названия заголовков (пунктов) Политики конфиденциальности
          предназначены исключительно для удобства пользования текстом Политики
          конфиденциальности и буквального юридического значения не имеют. Какие
          данные мы собираем <br />
          Мы собираем три категории данных о вас: данные, которые вы
          предоставляете нам; данные, которые мы собираем автоматически; и
          данные, которые мы получаем из других источников. <br />
          Мы не собираем и не обрабатываем информацию о вашей расовой или
          национальной принадлежности, политических взглядах или членстве в
          каком-либо политическом объединении, религиозных или философских
          взглядах, членстве в профсоюзе, генетические данные, биометрические
          данные или данные, касающиеся сексуальной жизни. <br />
          Данные, которые вы предоставляете <br />
          Регистрационные данные и данные профиля, такие как ваше имя, номер
          телефона, адрес электронной почты, страна, город, фото профиля. <br />
          Мы можем собирать номера телефонов с помощью вспомогательного
          инструмента - https://ca2.gootax.pro:8089 для экстренной связи, если
          это разрешено или требуется законодательством. <br />
          Мы можем собирать номера телефонов для экстренной связи, если это
          разрешено или требуется законодательством. <br />
          Пользовательский контент, который вы решаете загрузить при
          использовании Приложения и Сайта, например, комментарии, рейтинги и
          отзывы на других Пользователей.
          <br />
          Информация в сообщениях от вас, к которым могут относиться сообщения в
          чате, электронные письма и записи телефонных звонков, которые мы от
          вас получаем. Данные, которые мы собираем автоматически <br />
          Информация о местоположении. Мы собираем данные о местоположении
          Пользователей для обеспечения поездок, поддержки пользователей, в
          целях безопасности и борьбы с мошенничеством, а также для выполнения
          требований законодательства. Мы собираем данные о местоположении (в
          том числе координаты GPS и данные Wi-Fi) на основе выбранных вами
          настроек Приложения, разрешений устройства. <br />
          Мы собираем данные о точном местоположении вашего устройства, когда
          Приложение работает в активном режиме (Приложение открыто и
          отображается на дисплее) и когда Приложение работает в фоновом режиме
          (Приложение открыто, но на дисплее не отображается) с момента
          поступления вашей заявки на поездку до окончания поездки. Пользователи
          могут пользоваться Приложением, не разрешая ему собирать данные о
          точном местоположении со своих мобильных устройств, посредством
          прямого ввода адреса или координат точки начала и окончания своей
          поездки в Приложении. Это может влиять на работу Приложения. <br />
          Информация о транзакциях. Мы собираем данные о ваших транзакциях,
          связанных с использованием Услуг, включая тип запрошенных или
          оказанных Услуг, детали заказа, сведения о платежных транзакциях, дату
          и время оказания услуги, сумму оплаты, маршрут и пройденное
          расстояние, а также способ оплаты. <br />
          Информация о использовании Услуг. Мы собираем данные об использовании
          вами Услуг, включая дату и время пользования Приложением,
          использованные функции Приложения или просмотренные страницы, сбои в
          работе Приложения или Сайта и иные аспекты пользования. Мы также можем
          собирать и использовать в маркетинговых целях данные, относящиеся к
          сторонним сайтам, которые вы посетили, или к услугам, которыми вы
          пользовались до использования наших Услуг. <br />
          Информация о сообщениях и звонках. Мы предоставляем Пользователям
          возможность общаться друг с другом и с нами посредством Приложения. В
          целях предоставления данной услуги мы получаем данные о времени и дате
          сообщений и звонков и их содержании. Мы также можем использовать эти
          данные для поддержки пользователей, для обеспечения безопасности,
          урегулирования споров между пользователями, улучшения качества наших
          Услуг, а также в целях аналитики (например, для оценки и повышения
          эффективности Сайта). <br />
          Информация об устройстве. Мы собираем данные об устройстве, которое вы
          используете для получения Услуг, например данные о названии вашего
          устройства, марке и модели, пользовательском посреднике, IP-адресе,
          операторе мобильной связи, типе сети, настройках часового пояса,
          языковых настройках, рекламных идентификаторах, типе браузера,
          операционной системе и ее версии, параметрах экрана, степени
          заряженности аккумулятора и установленных приложениях, которые могут
          использоваться в целях аутентификации. Мы также можем собирать данные
          мобильного датчика, такие как скорость, направление, высота,
          ускорение, замедление, и другие технические данные. Файлы cookie,
          аналитика и сторонние технологии. Мы собираем данные с помощью файлов
          cookie, пикселей, тегов и аналогичных технологий («файлы cookie»).
          Файлы cookie — это небольшие текстовые файлы, которые веб-сервера
          размещают на вашем устройстве. Они осуществляют автоматический сбор
          информации, количественную оценку и анализ вашего использования сайтов
          и приложений, а также идентифицируют веб-страницы и рекламные
          объявления, на которые вы переходите. Информация, которую мы получаем
          посредством файлов cookie, помогает нам совершенствовать ваш
          пользовательских опыт при использовании Сервиса (например, с помощью
          сохранения ваших предпочтений) и наши маркетинговые программы. Наши
          подрядчики и деловые партнеры могут использовать данную информацию для
          отображения рекламных объявлений на ваших устройствах с учетом ваших
          индивидуальных предпочтений и характеристик. Вы можете удалить файлы
          cookie и управлять настройками cookie в настройках своего
          веб-браузера. Однако некоторые файлы cookie не могут быть
          деактивированы, поскольку от них зависит корректная работа нашего
          Сайта. Данные, которые мы получаем из других источников
          <br />
          Государственные органы. Мы можем получать информацию о Пользователях
          от правоохранительных и иных государственных органов в рамках
          поступившего от них запроса, в ходе судебного производства и в иных
          случаях, предусмотренных законодательством. <br />
          Другие Пользователи или третьи лица. Иногда Пользователи или иные лица
          могут предоставлять нам информацию о вас, в том числе через службу
          поддержки либо в связи с претензиями или спорами. Мы храним и
          используем такую информацию только в том случае, если мы могли бы
          получить ее от вас на законных основаниях. Кроме того, мы обязуемся
          предпринять необходимые действия, оправданные в сложившихся
          обстоятельствах, чтобы сообщить вам о получении такой информации.{" "}
          <br />
          Как мы используем ваши данные? <br />
          Мы используем персональную информацию о вашем местоположении с помощью
          вспомогательного инструмента - https://geo.gootax.pro. Этот инструмент
          позволяет по вашему запросу находить нужные адреса в режиме подсказок,
          рассчитывать расстояние между точками отправления и прибытия
          (назначения). Сервис не сохраняет ваши данные и не собирает
          статистику. <br />
          1. Для предоставления возможности пользования Приложением и Сайтом{" "}
          <br />– создания и ведения вашей учетной записи, настроек и
          предпочтений; <br />– обеспечения возможности оказывать услуги по
          перевозке или иные услуг (в зависимости от обстоятельств); <br />–
          расчета цен и осуществления платежей; – обеспечения возможности обмена
          сообщениями между Пользователями; <br />– поддержки Пользователей;{" "}
          <br />– отправки сообщений немаркетингового характера об Услугах;{" "}
          <br />– обеспечения доступности, безопасности и стабильности нашего
          Приложения и Сайтов, диагностики, предотвращения и решения технических
          проблем и проблем обслуживания; <br />– соблюдения законодательных
          требований, связанных с Услугами и тем, как вы их используете. <br />
          2. Для обеспечения безопасности использования Приложения, Сайта и
          Пользователей: <br />– предотвращения, выявления и пресечения случаев
          мошенничества, небезопасных или незаконных действий, нарушения наших
          политик и правил; <br />– аутентификации Пользователей; <br />– защиты
          персональных данных, прав и законных интересов Сервиса и наших
          Пользователей. <br />
          3. Для совершенствования существующих и разработки новых фичей и
          продуктов: <br />– проведения тестирования, исследований, анализа,
          разработки и машинного обучения с целью улучшения вашего
          пользовательского опыта с Сервисом; <br />– сбора обратной связи о
          вашем пользовательском взаимодействии с Сервисом; <br />– разработки
          новых продуктов, фичей и услуг. <br />
          4. Для продвижения Сервиса и наших услуг: <br />- отправлять
          push-уведомления в приложении; <br />- организовывать конкурсы,
          мероприятия и другие акции. Отключить push-уведомления вы можете в
          настройках устройства. (см. пункт Отключение push-уведомлений) <br />
          4. Для соблюдения требований законодательства. Мы можем использовать
          ваши персональные данные в целях соблюдения применимого
          законодательства, нормативно-правовых актов, требований лицензий и
          отраслевых стандартов, в рамках судебного производства, для ответа на
          запросы правоохранительных органов и в иных случаях, предусмотренных
          законодательством. <br />
          5. Автоматизированное принятие решений. Мы используем персональные
          данные для автоматизированного принятия решений в контексте вашего
          использования Услуг в целях: <br />– установления контакта между
          Пользователями (Водителями и Пассажирами) (или другими видами
          Пользователей в зависимости от контекста) исходя из таких факторов,
          как доступность и близость по расстоянию; <br />– определения
          рейтингов Пользователей и деактивации пользователей с низким
          рейтингом; <br />– обнаружения Пользователей, которые совершают
          мошеннические, небезопасные или причиняющие вред действия; <br />
          Если это требует законодательство, действия на основе такой обработки
          данных предпринимаются только после принятия решения человеком и (или)
          предоставления возможности обжалования соответствующего решения.{" "}
          <br />
          Как мы передаем ваши данные <br />
          Мы понимаем, насколько важно соблюдать конфиденциальность ваших
          персональных данных. В этом разделе мы описываем, в каких случаях и
          кому мы передаем полученные нами данные. <br />
          Другим Пользователям
          <br />
          Определенная информация о Пользователях необходима, чтобы вы могли
          заказывать и совершать поездки. В частности: <br />– информация о
          Пользователе, предоставляемая Водителю, включает имя и фотографию,
          рейтинг, адрес начала и окончания поездки, количество поездок,
          предлагаемую цену, комментарии и контактную информацию. Подрядчикам Мы
          можем предоставлять ваши персональные данные нашим подрядчикам в связи
          с бизнес-целями, в частности для: <br />– обработки платежей и
          осуществления транзакций; <br />– поддержки пользователей и
          технической поддержки; <br />– облачного хранения
          (https://ca2.gootax.pro);
          <br />– передачи контента, обмена сообщениями внутри приложения,
          совершения звонков внутри приложения, push-уведомлений; <br />– борьбы
          с мошенничеством; <br />– рекламы, маркетинга и аналитики. <br />В
          связи с передачей бизнеса <br />
          Мы можем передавать собранные данные в связи со значительной
          корпоративной сделкой, включая реструктуризацию, слияние, консолидацию
          или продажу активов, в рамках которой персональные данные входят в
          состав передаваемых активов. Мы приложим все возможные усилия для
          того, чтобы любой получатель ваших данных обрабатывал такие данные в
          соответствии с настоящей политикой. <br />В силу юридических причин
          <br />
          Мы можем раскрывать ваши персональные данные, когда это необходимо в
          целях: <br />– соблюдения законодательства, в том числе для ответа на
          судебные повестки, постановления суда, запросы правоохранительных или
          иных государственных органов или претензии; <br />– защиты прав,
          интересов и безопасности Сервиса, наших Пользователей или
          общественности; <br />– соблюдения требований, предусмотренных
          лицензиями и разрешениями, а также применимых законодательных и иных
          нормативно-правовых актов. <br />С вашего согласия <br />
          Мы можем передавать ваши персональные данные в иных целях с вашего
          согласия или по вашему распоряжению. <br />
          После анонимизации <br />
          Мы можем передавать ваши данные после их агрегирования и такой
          анонимизации, вследствие чего эти данные уже не могут быть связаны с
          вами, то есть перестают быть персональными. Ваши права и ваш выбор
          Доступ к вашим данным и их изменение. Вы вправе получать доступ к
          данным своей учетной записи и к иным данным, которые вы нам
          предоставили, а также просматривать и изменять их. Вы можете сделать
          это в настройках своей учетной записи либо обратившись в службу
          поддержки. <br />
          Предоставление информации о местоположении. Ваше устройство дает
          возможность контролировать, какую информацию мы собираем или как мы
          можем ее использовать. Например, вы можете запретить своему устройству
          передавать информацию о вашем местоположении в настройках устройства.
          Однако это может повлиять на функциональность Приложения. <br />
          Отключение push-уведомлений. Вы можете отказаться от push-уведомлений
          в настройках своего устройства. Отказ от push-уведомлений может
          повлиять на использование Услуг (например, вы не получите уведомление
          о том, что ваш Водитель прибыл на заказ). Управление файлами cookie.
          Вы можете удалять файлы cookie и изменять настройки cookie в своем
          браузере. Возможно, вам нужно будет отдельно настраивать получение
          файлов cookie в каждом браузере и на каждом устройстве. Если вы решите
          удалить файлы cookie или отказаться от них, мы не сможем предоставлять
          вам все запрашиваемые Услуги. <br />
          Удаление учетной записи и данных. Вы можете удалить свою учетную
          запись в настройках Приложения или обратиться за удалением вашей
          учетной записи или данных в службу поддержки. Мы можем попросить вас
          подтвердить владение учетной записью и пройти проверку личности. В
          некоторых случаях мы не сможем удалить вашу учетную запись и будем
          вправе сохранить определенную информацию в законных бизнес-целях,
          например, для завершения транзакции, с целью совершения которой мы
          собрали персональные данные, возврата задолженности, выявления угроз
          кибербезопасности, устранения неполадок или исправления недостатков
          наших Услуг, обеспечения права на свободу слова или иных прав другого
          Пользователя, использования исключительно для внутренних нужд в
          соответствии с контекстом, в котором персональные данные были
          предоставлены, для предотвращения мошенничества, выполнения
          законодательных или иных нормативных требований и претензионной работы
          с связи с Услугами. В этих случаях мы сохраняем данные таким образом,
          чтобы предотвратить их использование в иных целях. <br />
          Направление вопросов или жалоб. Мы готовы ответить на любые ваши
          вопросы о настоящей политике или порядке обработки нами ваших данных.
          Мы также готовы рассмотреть любую жалобу касательно обработки нами
          ваших персональных данных. Мы ответим на ваше обращение в соответствии
          с применимым законодательством после надлежащей верификации. <br />
          Как мы храним ваши данные <br />
          Мы храним ваши данные в течение того периода, который необходим для
          предоставления Услуг и достижения иных целей, указанных в настоящей
          политике. <br />
          Сроки хранения различаются в зависимости от типа данных и целей, в
          которых мы используем эти данные. <br />
          Когда мы обрабатываем ваши данные для предоставления Услуги, мы храним
          данные пока ваша учетная запись не удалена. Мы вправе хранить данные
          Пользователя после удаления учетной записи в соответствии с
          законодательными или иными нормативными требованиями или по причинам,
          указанным в настоящей политике, в том числе в пункте «Удаление учетной
          записи и данных». Например, мы будем хранить ваши данные при наличии
          непогашенной задолженности по вашей учетной записи. Мы также храним
          данные с целью предоставления ответа на любые претензии в отношении
          Услуг. <br />
          Как мы защищаем ваши данные <br />
          Мы принимаем обоснованные и надлежащие технические, организационные и
          физические меры безопасности, соответствующие характеру персональных
          данных, чтобы защитить ваши данные от потери, кражи, неправомерного
          использования и несанкционированного доступа, раскрытия, изменения и
          уничтожения. <br />
          Мы применяем стандартные отраслевые технологии и методы, чтобы
          гарантировать безопасность пользовательских данных, а также
          предотвратить их несанкционированное использование. <br />В частности:{" "}
          <br />– регулярно обновляем технические платформы, на которых сделаны
          Сервисы;
          <br />– храним пароли пользователей в зашифрованном виде; <br />–
          регулярно проверяем на предмет наличия вредоносных кодов; <br />–
          применяем современные методы шифрования передаваемых данных по
          протоколу HTTPS;
          <br />– другие. <br />
          Мы регулярно пересматриваем наши меры безопасности с учетом новых
          технологий и методов. Однако гарантированной безопасности не
          существует как в сети Интернет, так и за ее пределами. Мы не можем
          гарантировать безопасность ваших данных, в том числе, обеспечить
          гарантированную защиту от несанкционированного доступа или действий
          третьих лиц. При этом мы принимаем коммерчески обоснованные меры,
          чтобы уровень безопасности ваших данных соответствовали настоящей
          политике и всем применимым законодательным и иным нормативным
          требованиям. Мы рекомендуем время от времени менять пароль входа.
          <br />
          Общие сведения <br />
          Защита детей. Наши Услуги предназначены для совершеннолетних, а не для
          детей. Если нам предоставит персональные данные лицо, которому,
          насколько нам известно, еще не исполнилось 18 лет, мы примем
          обоснованные меры, чтобы не допустить последующее использование или
          раскрытие таких данных и удалить полученные данные. Если вы полагаете,
          что несовершеннолетний предоставил нам персональные данные, просим вас
          связаться с нами. <br />
          Сбор данных третьими лицами <br />
          Мы не несем ответственности за применяемые третьими лицами практики в
          отношении персональных данных. Мы не контролируем ни самих третьих
          лиц, ни порядок сбора, использования или раскрытия ими ваших данных.
          Настоящая политика не распространяется на сбор, использование или
          раскрытие ваших персональных данных третьими лицами. Если у вас есть
          вопросы о практиках третьих лиц в отношении персональных данных,
          пожалуйста, обратитесь к соответствующему третьему лицу напрямую.
          <br />
          Изменения <br />
          Мы можем вносить изменения в настоящую политику с учетом изменений в
          законодательстве или в порядке предоставления услуг. При внесении
          изменений в Политику конфиденциальности мы уведомим вас о таких
          изменениях посредством актуализации «Даты последнего обновления»
          вверху этой страницы, размещения новой Политики конфиденциальности и
          направления уведомления, предусмотренного применимым
          законодательством. Мы рекомендуем вам периодически просматривать
          данную страницу, чтобы быть в курсе наших практик в отношении
          персональных данных. Используя Сервис (включая Приложение и/или Сайт),
          вы соглашаетесь с актуальными условиями настоящей политики. При
          несогласии с Политикой конфиденциальности (в том числе при несогласии
          с обновленной Политикой конфиденциальности) вы обязаны прекратить
          использование Сервиса (включая Приложение и Сайт).
        </p>

        <button
          className={styles.closeBtn}
          onClick={() => {
            onClose();
          }}
        >
          Закрыть
        </button>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
