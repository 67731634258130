import React from "react";
import { useNavigate } from "react-router-dom";
import appStoreIcon from "../../../assets/APPLE.png";
import googlePlayIcon from "../../../assets/GOOGLE.png";
import call from "../../../assets/call.png";
import instaButton from "../../../assets/instabutton.png";
import logo from "../../../assets/logo.png";
import whatsapp from "../../../assets/whatsapp.png";
import styles from "./Home.module.scss";

const Home: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.titleCont}>
          <img src={logo} alt="SMART Taxi" className={styles.logo} />
          <div className={styles.title}>SMART Taxi</div>
          Удобный сервис для заказа <br /> такси на всей территории Чеченской
          Республики!
        </div>
        <div className={styles.appButtons}>
          <a
            href="https://instagram.com/smart95taxi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={instaButton}
              alt="Instagram"
              className={styles.appButton}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.smarttrans.client&hl=ru&gl=ru"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={googlePlayIcon}
              alt="Google Play"
              className={styles.appButton2}
            />
          </a>
          <a
            href="https://apps.apple.com/ru/app/smart-trans-%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7-%D1%82%D0%B0%D0%BA%D1%81%D0%B8/id6478095820?clckid=eeb9d3fd"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={appStoreIcon}
              alt="App Store"
              className={styles.appButton2}
            />
          </a>

          <button
            className={styles.registerBtn}
            onClick={() => navigate("/register")}
          >
            Стать водителем
          </button>
          <div className={styles.contactsBtn}>
            <a href="https://wa.me/+79388950995">
              <img src={whatsapp} alt="WhatsApp" className={styles.whBtn} />
            </a>
            <a href="tel:+79388950995">
              <img src={call} alt="Call" className={styles.callBtn} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
