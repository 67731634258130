import { useEffect, useState } from "react";
import { ICarBrand, ICarColor } from "../interfaces";

const useFetchCarColors = (currentTime: number) => {
  const [carColorList, setCarColorList] = useState<ICarColor[]>([]);

  useEffect(() => {
    const fetchCarColors = async () => {
      try {
        const response = await fetch(
          `api/get_colors?current_time=${currentTime}`
        );
        const data = await response.json();

        const colorEntries = Object.entries(data.result.color);
        const colors: ICarBrand[] = colorEntries.map(([id, name]) => ({
          id,
          name: name as string,
        }));

        setCarColorList(colors);
      } catch (error) {
        console.error("Error fetching car colors:", error);
      }
    };

    fetchCarColors();
  }, [currentTime]);

  return carColorList;
};

export default useFetchCarColors;
