import { useEffect, useState } from "react";

const useFetchBranches = (currentTime: number) => {
  const [branchList, setBranchList] = useState([]);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await fetch(
          `/api/get_tenant_city_list?current_time=${currentTime}`
        );
        const data = await response.json();
        setBranchList(data.result.city_list);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    fetchBranches();
  }, [currentTime]);

  return branchList;
};

export default useFetchBranches;
