import React from "react";
import { IBranch } from "../../interfaces";

interface BranchSelectProps {
  branches: IBranch[];
  selectedBranch: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const BranchSelect: React.FC<BranchSelectProps> = ({
  branches,
  selectedBranch,
  onChange,
}) => (
  <div>
    <p>Город</p>
    <select value={selectedBranch} onChange={onChange}>
      <option value="">Выберите город</option>
      {branches.map((branch) => (
        <option key={branch.city_id} value={branch.city_id}>
          {branch.city_name}
        </option>
      ))}
    </select>
  </div>
);

export default BranchSelect;
