import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import RegistrationSuccessPage from "./components/pages/Home/Home";
import RegisterDriverPage from "./components/pages/RegisterPage/RegisterPage";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/register" element={<RegisterDriverPage />} />
        <Route path="/" element={<RegistrationSuccessPage />} />
      </Routes>
    </Router>
  );
};

export default App;
