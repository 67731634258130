import React from "react";
import { ICarModel } from "../../../interfaces";

interface CarModelSelectProps {
  carModels: ICarModel[];
  selectedCarModel: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const CarModelSelect: React.FC<CarModelSelectProps> = ({
  carModels,
  selectedCarModel,
  onChange,
}) => (
  <div>
    <p>Модель</p>
    <select value={selectedCarModel} onChange={onChange} required>
      <option value="">Выберите модель авто</option>
      {carModels.map((model) => (
        <option key={model.id} value={model.id}>
          {model.name}
        </option>
      ))}
    </select>
  </div>
);

export default CarModelSelect;
